import { AxiosRequestConfig, AxiosResponse, AxiosError } from "axios";
import CryptoJS from "crypto-js";

import { BearerProfile } from "../types";

type AuthProfile = Record<string, any> | null;

/**
 * If `active-profile` is set in local storage, this function returns that active-profile for the purposes
 * of injecting headers into the Axios Request Config.
 */
function getAuthProfile(): AuthProfile {
  try {
    return JSON.parse(localStorage.getItem("active-profile") as string);
  } catch (err) {
    console.error(err);
    return null;
  }
}

/**
 * Bearer Strategy for Mgmt API, Resident API, and Fusion API
 */
function bearerAuthStrategy({ accessToken }: BearerProfile) {
  return {
    Authorization: `Bearer ${accessToken}`,
  };
}

/**
 * Auth Strategy for Internal API and Vendor API
 */
function hmacAuthStrategy({
  secret,
  key,
  queryString,
  path,
  method,
  body,
}: {
  secret: string;
  key: string;
  queryString?: string;
  path: string;
  method: string;
  body: any;
}) {
  const timestamp = Math.round(new Date().getTime() / 1000);
  const bodyOrString = body ? JSON.stringify(body) : "";
  const message = `${timestamp}\n${method}\n${path}${
    queryString ? `?${queryString}` : ""
  }\n${bodyOrString}`;

  return {
    "alloy-access-ts": timestamp,
    "alloy-access-signature": CryptoJS.enc.Hex.stringify(
      CryptoJS.HmacSHA512(message, secret)
    ),
    "alloy-access-key": key,
  };
}

/**
 * Attempts to apply various known CMW Auth strategies to the Request Headers based on what is contained in AuthProfile.
 *
 * Auth Strategies that we attempt to apply:
 *
 * - Bearer
 * - HmacChecksum
 */
function applyAuthHeaders({
  profile,
  path,
  queryString,
  method,
  body,
}: {
  profile: AuthProfile;
  body: null | Record<string, any>;
  path: string;
  queryString?: string;
  method: string;
}): Record<string, any> {
  if (profile?.accessToken) {
    return bearerAuthStrategy({ accessToken: profile.accessToken });
  } else if (profile?.key && profile?.secret) {
    return hmacAuthStrategy({
      secret: profile.secret,
      key: profile.key,
      queryString,
      path,
      method,
      body,
    });
  }

  return {};
}

export const applyAxiosInterceptors = ({
  axios,
  storeHistoricResponse,
}: {
  axios: any;
  storeHistoricResponse: any;
}) => {
  axios.interceptors.request.use((config: AxiosRequestConfig) => {
    const profile = getAuthProfile();
    const queryString =
      config?.paramsSerializer &&
      typeof config.paramsSerializer === "function" &&
      config?.params
        ? config.paramsSerializer(config.params)
        : ""; // string
    const body: Record<string, any> | null = config.data;
    const method = config.method ? config.method.toUpperCase() : "GET";
    //const path = config.url.replace(config.baseURL, "");
    const path = config.url
      ? config.url.replace(process.env.API_BASE_URL as string, "")
      : "";

    return {
      ...config,
      data: config.data || undefined,
      headers: {
        ...config?.headers,
        ...applyAuthHeaders({ profile, body, path, queryString, method }),
        "content-type": "application/json",
      },
    };
  });

  axios.interceptors.response.use(
    (response: AxiosResponse) => {
      storeHistoricResponse({
        metadata: { profile: getAuthProfile() },
        response,
        error: null,
      }); // Adds success response to BadMagic's `History` tab
      return response;
    },
    (error: AxiosError) => {
      const metadata = { profile: getAuthProfile() };
      storeHistoricResponse({
        metadata,
        response: null,
        error,
      }); // Adds error response to BadMagic's `History` tab
      return Promise.reject(error);
    }
  );

  return axios;
};
