import { Route } from "badmagic/dist/types";

import { IcvtOptions } from "../../../../../modules/input/types";

export const inputRoutes: Route[] = [
  {
    name: "List Inputs",
    path: `/inputs`,
    qsParams: [
      { name: "controller_id", type: "number" },
      { name: "panel_id", type: "number" },
      { name: "panel_ids", type: "array" },
    ],
  },
  {
    name: "Get Input",
    path: `/inputs/:input_id`,
  },
  {
    name: "Update Input",
    path: `/inputs/:input_id`,
    method: "PATCH",
    body: [
      { name: "icvt_num", options: IcvtOptions },
      { name: "debounce", type: "number" },
      { name: "hold_time", type: "number" },
    ],
  },
];
