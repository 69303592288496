import { Route } from "badmagic/dist/types";

export const lockRoutes: Route[] = [
  {
    name: "List Locks",
    path: "/devices/",
  },
  {
    name: "Get lock",
    path: "/devices/:device_id",
  },
];
