import { apiClient } from "@/lib/api";

interface UserApplicationMetadata {
  /** ui friendly name of the application */
  label: string;
  subtitle: string;
  icon_name: string;
  tags: string[];
  is_mobile_app?: boolean;
}

interface UserApplication {
  /** id from the user record of the application */
  user_id: string | null;
  /** code friendly name of the application */
  name: string;
  /** metadata for the application */
  meta: UserApplicationMetadata;
}
interface FetchAppsResponse {
  /** umbrella user's id */
  id: string;
  /** list of all available applications */
  applications: UserApplication[];
}

type OnPressAppFn = (app: UserApplication) => Promise<string>;

interface GetApplicationRedirectLinkResponse {
  redirect_uri: string;
}

interface GetApplicationRedirectLinkResponse {
  redirect_uri: string;
}

export const fetchApps = async (): Promise<FetchAppsResponse> => {
  const { data } = await apiClient.get<FetchAppsResponse>(
    "/v1/public/my-applications"
  );

  return data;
};

export const onPressApp: OnPressAppFn = async (app) => {
  const { data } = await apiClient.get<GetApplicationRedirectLinkResponse>(
    `/v1/public/my-applications/redirect/${app.name}`
  );

  return data.redirect_uri;
};
