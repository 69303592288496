import { Route } from "badmagic/dist/types";

import { booleanOptions } from "../shared";

export const sipAccountRoutes: Route[] = [
  {
    name: "List Sip Accounts",
    path: `/sip-accounts`,
    qsParams: [{ name: "controller_id", required: true }],
  },
  {
    name: "Create Sip Account",
    path: `/sip-accounts`,
    method: "POST",
    body: [
      {
        name: "controller_id",
        required: true,
      },
      { name: "account_number" },
      { name: "account_active", options: booleanOptions },
      { name: "display_name" },
      { name: "register_name" },
      { name: "user_name" },
      { name: "password" },
      { name: "sip_server_host" },
      { name: "sip_server_port" },
      { name: "stun_enable", options: booleanOptions },
      { name: "stun_server" },
      { name: "stun_port" },
    ],
  },
  {
    name: "Get Sip Account",
    path: `/sip-accounts/:sip_account_id`,
  },
  {
    name: "Update Sip Account",
    path: `/sip-accounts/:sip_account_id`,
    method: "PATCH",
    body: [
      { name: "account_number" },
      { name: "account_active", options: booleanOptions },
      { name: "display_name" },
      { name: "register_name" },
      { name: "user_name" },
      { name: "password" },
      { name: "sip_server_host" },
      { name: "sip_server_port" },
      { name: "stun_enable", options: booleanOptions },
      { name: "stun_server" },
      { name: "stun_port" },
    ],
  },
  {
    name: "Delete Sip Account",
    path: `/sip-accounts/:sip_account_id`,
    method: "DELETE",
  },
];
