import { Route } from "badmagic/dist/types";

export const memberAccessLevelRoutes: Route[] = [
  {
    name: "Assign an access level to a member",
    method: "POST",
    path: "/member-access-levels/:member_id/:access_level_id",
  },
  {
    name: "Set a members access levels",
    method: "PUT",
    path: "/member-access-levels/",
    body: [
      { name: "member_id", type: "number" },
      {
        name: "access_level_ids",
        array: true,
        type: "number",
      },
    ],
  },
  {
    name: "Remove an access level from a member",
    method: "DELETE",
    path: "/member-access-levels/:member_id/:access_level_id",
  },
];
