import { Route } from "badmagic/dist/types";

export const permissionsRoutes: Route[] = [
  {
    name: "Get Permissions",
    path: "/permissions",
  },
  {
    name: "Get Permissions CSV",
    path: "/permissions/csv",
  },
];
