import { Route } from "badmagic/dist/types";

// import { booleanOptions } from "../shared";

export const passRoutes: Route[] = [
  {
    name: "SMS Token",
    method: "POST",
    path: "/session/sms-token",
    body: [{ name: "phone_number", required: true }],
  },
  { name: "Get Profile", path: "/profile" },
  { name: "Get Passes", path: "/passes" },
  { name: "Get Events", path: "/events" },
  {
    name: "Provision Level Device",
    method: "POST",
    path: "/devices/provision/level",
    body: [{ name: "device_info", required: true }],
  },
  {
    name: "Provision Schlage Device",
    method: "POST",
    path: "/devices/provision/schlage",
    body: [
      { name: "duid", required: true },
      { name: "serial", required: true },
    ],
  },
  {
    name: "Sync Schlage Device",
    method: "PATCH",
    path: "/devices/sync/schlage",
    body: [{ name: "serial", required: true }],
  },
  {
    name: "Create Schlage Events",
    method: "POST",
    path: "/devices/events/schlage",
    body: [
      {
        name: "serial",
        required: true,
      },
      {
        name: "audits",
        required: true,
        array: true,
        properties: [
          { name: "event", required: true, type: "string" },
          { name: "time", required: true, type: "string" },
        ],
      },
    ],
  },
  {
    name: "Create Device",
    method: "POST",
    path: "/devices",
    body: [
      { name: "id", required: true },
      { name: "type", required: true },
      { name: "serial", required: true },
    ],
  },
];
