import { Route } from "badmagic/dist/types";

import { PanelModelOptions } from "@/modules/panel/types";

export const panelRoutes: Route[] = [
  {
    name: "List Panels",
    path: `/panels`,
    qsParams: [{ name: "controller_id", type: "number", required: true }],
  },
  {
    name: "Create Panel",
    path: `/panels`,
    method: "POST",
    body: [
      { name: "controller_id", required: true },
      { name: "name", required: true },
      { name: "model", required: true, options: PanelModelOptions },
      { name: "address" },
      { name: "port" },
    ],
  },
  {
    name: "Get Panel",
    path: `/panels/:panel_id`,
  },
  {
    name: "Update Panel",
    path: `/panels/:panel_id`,
    method: "PATCH",
    body: [{ name: "name" }, { name: "address" }, { name: "port" }],
  },
  {
    name: "Delete Panel",
    path: `/panels/:panel_id`,
    method: "DELETE",
  },
];
