import { accessLevelRoutes } from "./access-levels";
import { apiApplicationRoutes } from "./api-applications";
import { cardRoutes } from "./card";
import { controllerRoutes } from "./controller";
import { credentialRoutes } from "./credentials";
import { doorRoutes } from "./doors";
import { floorRoutes } from "./floors";
import { hubRoutes } from "./hubs";
import { lockRoutes } from "./locks";
import { memberRoutes } from "./members";
import { organizationRoutes } from "./organizations";
import { passRoutes } from "./passes";
import { siteRoutes } from "./site";
import { userAuthRoutes } from "./user-auth";
import { userRoutes } from "./users";
import { scheduleRoutes } from "./schedules";
import { siteImportRoutes } from "./site-import";
import { permissionsRoutes } from "./permissions";
import { memberAccessLevelRoutes } from "./member-access-levels";
import { scheduleIntervalRoutes } from "./schedule-intervals";
import { deviceRoutes } from "./devices";
import { panelRoutes } from "./panel";
import { readerRoutes } from "./reader";
import { outputRoutes } from "./output";
import { inputRoutes } from "./input";
import { programmableVideoRoutes } from "./programmable-video";
import { sipAccountRoutes } from "./sip-accounts";
import { firmwareRoutes } from "./firmware";
import { firmwareVersionRoutes } from "./firmware-versions";
import { eventRoutes } from "./events";
import { devRoutes } from "./dev";

export const routes = [
  ...accessLevelRoutes,
  ...apiApplicationRoutes,
  ...cardRoutes,
  ...controllerRoutes,
  ...credentialRoutes,
  ...doorRoutes,
  ...floorRoutes,
  ...hubRoutes,
  ...lockRoutes,
  ...memberRoutes,
  ...organizationRoutes,
  ...passRoutes,
  ...siteRoutes,
  ...userAuthRoutes,
  ...userRoutes,
  ...scheduleRoutes,
  ...siteImportRoutes,
  ...permissionsRoutes,
  ...memberAccessLevelRoutes,
  ...scheduleIntervalRoutes,
  ...deviceRoutes,
  ...panelRoutes,
  ...readerRoutes,
  ...outputRoutes,
  ...inputRoutes,
  ...programmableVideoRoutes,
  ...sipAccountRoutes,
  ...firmwareRoutes,
  ...firmwareVersionRoutes,
  ...eventRoutes,
  ...devRoutes,
];
