import { Route } from "badmagic/dist/types";

import { booleanOptions } from "../shared";

export const devRoutes: Route[] = [
  {
    name: "Generate Members",
    path: `/dev/members`,
    method: "POST",
    body: [
      { name: "site_id" },
      { name: "num_members", type: "number" },
      {
        name: "generate_credentials",
        options: booleanOptions,
        defaultValue: false,
      },
      { name: "access_levels", type: "number", array: true },
    ],
  },
  {
    name: "Get Site Configuration",
    path: `/dev/sites/:site_id/config/:key`,
  },
];
