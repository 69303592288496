import { Route } from "badmagic/dist/types";

export const deviceRoutes: Route[] = [
  {
    name: "Get site devices",
    path: "/devices",
    qsParams: [{ name: "site_id", required: true }],
  },
  {
    name: "Get device",
    path: "/devices/:device_id",
  },
];
