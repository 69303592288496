import { Route } from "badmagic/dist/types";

export const userAuthRoutes: Route[] = [
  {
    name: "Get User Info",
    path: "/me",
  },
  {
    name: "Get User Permissions",
    path: "/policy",
  },
];
