import { Route } from "badmagic/dist/types";

import { OutputModeOptions } from "../../../../../modules/output/types";

export const outputRoutes: Route[] = [
  {
    name: "List Outputs",
    path: `/outputs`,
    qsParams: [
      { name: "controller_id", type: "number" },
      { name: "panel_id", type: "number" },
      { name: "panel_ids", type: "array" },
    ],
  },
  {
    name: "Get Output",
    path: `/outputs/:output_id`,
  },
  {
    name: "Update Output",
    path: `/outputs/:output_id`,
    method: "PATCH",
    body: [{ name: "mode", options: OutputModeOptions }],
  },
];
