import { Route } from "badmagic/dist/types";

export const scheduleRoutes: Route[] = [
  {
    name: "List Schedules",
    path: `/schedules`,
    qsParams: [{ name: "site_id" }],
  },
  {
    name: "Create Schedule",
    path: `/schedules`,
    method: "POST",
    body: [{ name: "name" }, { name: "site_id" }],
  },
  {
    name: "Get Schedule",
    path: `/schedules/:schedule_id`,
  },
  {
    name: "Update Schedule",
    path: `/schedules/:schedule_id`,
    method: "PATCH",
    body: [{ name: "name" }],
  },
  {
    name: "Delete Schedule",
    path: `/schedules/:schedule_id`,
    method: "DELETE",
  },
];
