import { Route } from "badmagic/dist/types";

import {
  DataFormatOptions,
  KeypadModeOptions,
  WiegandLedDriveModeOptions,
} from "@/modules/reader/types";

export const readerRoutes: Route[] = [
  {
    name: "List Readers",
    path: `/readers`,
    qsParams: [
      { name: "controller_id", type: "number" },
      { name: "panel_id", type: "number" },
    ],
  },
  {
    name: "Get Reader",
    path: `/readers/:reader_id`,
  },
  {
    name: "Update Reader",
    path: `/readers/:reader_id`,
    method: "PATCH",
    body: [
      { name: "keypad_mode", options: KeypadModeOptions },
      { name: "led_drive_mode", options: WiegandLedDriveModeOptions },
      { name: "dt_fmt", options: DataFormatOptions, array: true },
    ],
  },
];
