import { Route } from "badmagic/dist/types";

export const passRoutes: Route[] = [
  {
    name: "Get passes",
    path: "/passes",
    qsParams: [
      { name: "member_id", required: true },
      { name: "type" },
      { name: "device_id" },
      { name: "name" },
      { name: "location" },
    ],
  },
  {
    name: "Get pass",
    path: "/passes/:pass_id",
  },
  {
    name: "Create a pass",
    path: "/passes",
    method: "POST",
    body: [
      { name: "member_id", required: true },
      { name: "device_id", required: true },
      { name: "name", required: true },
      { name: "location", required: true },
      {
        name: "type",
        options: [
          { label: "Access", value: "access" },
          { label: "Admin", value: "admin" },
        ],
      },
    ],
  },
  {
    name: "Delete pass",
    path: "/passes/:pass_id",
    method: "DELETE",
  },
];
