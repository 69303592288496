import { Route } from "badmagic/dist/types";

export const siteImportRoutes: Route[] = [
  {
    name: "List Imports",
    path: `/imports`,
  },
  {
    name: "Create Import",
    path: `/imports`,
    method: "POST",
    body: [{ name: "name" }],
  },
  {
    name: "Get Imports",
    path: `/imports/:import_id`,
  },
  {
    name: "Update Imports",
    path: `/imports/:import_id`,
    method: "PATCH",
    body: [{ name: "name" }],
  },
  {
    name: "Delete Imports",
    path: `/imports/:import_id`,
    method: "DELETE",
  },
];
