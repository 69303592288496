import { BaseRecord } from "../base/types";
import { Controller } from "../controller/types";
import { Input } from "../input/types";
import { Output } from "../output/types";
import { Reader } from "../reader/types";

export interface Panel extends BaseRecord {
  controller_id?: number;
  controller: Controller;
  inputs?: Input[];
  outputs?: Output[];
  readers?: Reader[];
  name?: string;
  panel_number?: number;
  model?: PanelModels;
  enable?: boolean;
  port?: PanelPort;
  channel_out?: number;
  channel_in?: number;
  address?: number;
  max_retries?: number;
  flags?: number;
  synced_at?: string;
  online?: boolean;
  status?: string;
  firmware_version?: string;

  cascading_in: boolean;
  cascading_out: boolean;
  order_in?: number;
  order_out?: number;

  cascading_options?: any;

  encryption_config?: string;
  encryption_key_status?: string;
  oem_code?: number;
  serial_number?: string;
  fw_download_progress?: number;
  fw_error?: string;
  fw_update_progress?: number;
  inserted_at: string;
}

export enum PanelModels {
  lp1501_internal = "lp1501_internal",
  lp1502_internal = "lp1502_internal",
  lp4502_internal = "lp4502_internal",
  mp1501_internal = "mp1501_internal",
  mp1502_internal = "mp1502_internal",
  verge = "verge_internal",
  aacbt_internal = "aacbt_internal",
  mr_50 = "mr50",
  mr_50_idh = "mr50idh",
  mr_52 = "mr52",
  mr_16_in = "mr16in",
  mr_16_out = "mr16out",
  mr_dt = "mrdt",
  mr_51_e = "mr51e",
  mr_62_e = "mr62e",
  blu_ic2_internal = "blu_ic2_internal",
  blu_ic4_internal = "blu_ic4_internal",
}

export const PanelModelOptions = [
  { value: PanelModels.mr_50, label: PanelModels.mr_50 },
  { value: PanelModels.mr_52, label: PanelModels.mr_52 },
  { value: PanelModels.mr_16_in, label: PanelModels.mr_16_in },
  { value: PanelModels.mr_16_out, label: PanelModels.mr_16_out },
];

export const InternalPanelModelOptions = [
  { value: PanelModels.lp1501_internal, label: PanelModels.lp1501_internal },
  { value: PanelModels.lp1502_internal, label: PanelModels.lp1502_internal },
  { value: PanelModels.lp4502_internal, label: PanelModels.lp4502_internal },
  { value: PanelModels.mp1501_internal, label: PanelModels.mp1501_internal },
  { value: PanelModels.mp1502_internal, label: PanelModels.mp1502_internal },
];

export const ElevatorCascadingPanelModelsOut = [
  PanelModels.mr_50,
  PanelModels.mr_52,
  PanelModels.lp1502_internal,
  PanelModels.mr_16_out,
];

export const ElevatorCascadingPanelModelsIn = [
  PanelModels.mr_50,
  PanelModels.mr_52,
  PanelModels.lp1502_internal,
  PanelModels.mr_16_in,
];

export const ValidPanelModels = [
  PanelModels.lp1501_internal,
  PanelModels.lp1502_internal,
  PanelModels.lp4502_internal,
  PanelModels.mp1501_internal,
  PanelModels.mp1502_internal,
  PanelModels.verge,
  PanelModels.aacbt_internal,
  PanelModels.mr_50,
  PanelModels.mr_50_idh,
  PanelModels.mr_52,
  PanelModels.mr_16_in,
  PanelModels.mr_16_out,
  PanelModels.mr_dt,
  PanelModels.mr_51_e,
  PanelModels.mr_62_e,
  PanelModels.blu_ic2_internal,
  PanelModels.blu_ic4_internal,
];

export const UndeletablePanels = [
  PanelModels.lp1501_internal,
  PanelModels.lp1502_internal,
  PanelModels.lp4502_internal,
  PanelModels.mp1501_internal,
  PanelModels.mp1502_internal,
  PanelModels.verge,
  PanelModels.aacbt_internal,
  PanelModels.blu_ic2_internal,
  PanelModels.blu_ic4_internal,
];

export enum PanelPort {
  internal = 0,
  tb3 = 1,
}

export const ValidPanelPorts = [PanelPort.internal, PanelPort.tb3];
