import { useEffect } from "react";
import { setTag, setUser } from "@sentry/react";
import { useQueryClient } from "@tanstack/react-query";
import { QueryKeys } from "@/types";
import { Site } from "@/modules/site/types";
import { useAuthUser } from "./Auth";

// This component is responsible for managing Sentry context via hooks
// it is a side-effect manager and does not render any UI
const SentryManager = () => {
  const queryClient = useQueryClient();
  const user = useAuthUser();

  // Set Sentry user with auth hooks user object
  useEffect(() => {
    if (user !== null && user !== void 0 && user.id) {
      var _user$email;
      setUser({
        id: user.id,
        email: (_user$email = user.email) !== null && _user$email !== void 0 && _user$email.endsWith("@smartrent.com") ? user.email : undefined
      });
    }
  }, [user === null || user === void 0 ? void 0 : user.id, user === null || user === void 0 ? void 0 : user.email]);
  useEffect(() => {
    // Set Sentry site tags when sites react-query cache changes
    const unsubscribeSite = queryClient.getQueryCache().subscribe(event => {
      var _event$query$queryKey;
      if (((_event$query$queryKey = event.query.queryKey) === null || _event$query$queryKey === void 0 ? void 0 : _event$query$queryKey[0]) === QueryKeys.Sites) {
        if (event.type === "added" || event.type === "updated") {
          updateSentrySiteTags(event.query.state.data);
        }
      } else if (event.type === "removed") {
        clearSentrySiteTags();
      }
    });

    // Cleanup subscription when component unmounts
    return () => unsubscribeSite();
  }, [queryClient]);
  return null; // No UI, this is just a side-effect manager
};
const updateSentrySiteTags = site => {
  setTag("site_id", site === null || site === void 0 ? void 0 : site.id);
  setTag("site_name", site === null || site === void 0 ? void 0 : site.name);
};
const clearSentrySiteTags = () => {
  setTag("site_id", undefined);
  setTag("site_name", undefined);
};
export default SentryManager;